body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none !important;
}

.ant-table-row-expand-icon-collapsed {
  display: none !important;
  width: 0 !important;
}

/* Privacy Policy */
.custom-container {
  margin: 20px auto;
  padding: 0 15px 20px 15px;
  width: 100%;
}

.custom-container-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #001529;
  width: 100%;
  height: 80px;
}

.custom-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 10px;
  width: 100%
}

.custom-container br {
  margin-bottom: 15px !important;
}

.custom-container ul {
  list-style-type: square;
}

@media (min-width: 576px) {

  .custom-container,
  .custom-container-header {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .custom-container,
  .custom-container-header {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .custom-container,
  .custom-container-header {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .custom-container,
  .custom-container-header {
    max-width: 1140px;
  }
}